








































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import {Emit, Prop, Watch} from "vue-property-decorator";
import GenproxDropdownMenu from '../GenproxDropdownMenu.vue';
import { SelectOption } from '@/store/types';
import { ChevronDownIcon, MagnyfyingGlassIcon, PlusCircleIcon, XMarkIcon } from '@/assets/icons/heroicons/heroicons';
import SygniCheckbox from './SygniCheckbox.vue';
import { BTooltip } from 'bootstrap-vue';
import SygniInput from './SygniInput.vue';
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
import DatePicker from 'vue2-datepicker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment';
import Utils from '@/modules/shared/utils/utils';

// TODO: Make some description about how does it works.
// Currency format cant be used with number input
// Refactor this someday please
@Component({
  components: { GenproxDropdownMenu, ChevronDownIcon, PlusCircleIcon, MagnyfyingGlassIcon, XMarkIcon, SygniCheckbox, BTooltip, SygniInput, DateRangePicker, DatePicker }
})
export default class GenproxField extends Vue {
  @Prop({default: 'text'}) type: 'text' | 'email' | 'range' | 'dateRange' | 'dateMonth' | 'select' | 'multiselect';
  @Prop({default: true }) searchable: boolean;
  @Prop({default: 'body'}) boundary: string;
  @Prop({default: 'bottom'}) placement: string;
  @Prop({default: null}) componentId: string;
  @Prop({default: null}) disabledDate: any;
  @Prop({default: ''}) placeholder: string;
  @Prop({default: ''}) label: string;

  @Prop({ default: false }) isDisabled: boolean;
  @Prop() options: SelectOption<any>[];
  @Prop() value!: any;
  @Prop() displayFormat!: string;
  @Prop() icon!: any;

  MagnyfyingGlassIcon = MagnyfyingGlassIcon;
  XMarkIcon = XMarkIcon;
  PlusCircleIcon = PlusCircleIcon;
  ChevronDownIcon = ChevronDownIcon;
  modelValue: any = '';
  displayValue: string = '';
  searchQuery: string = ''
  tempDate: { startDate: any, endDate: any } = {
    startDate: null,
    endDate: null,
  }
  tempInputDate: { startDate: any, endDate: any } = {
    startDate: null,
    endDate: null,
  }
  monthValue: any = null

  setMonthRange(date: string) {
    let dateTime = new Date(date);
    dateTime = new Date(dateTime.getFullYear(), dateTime.getMonth() + 1, 0);
    const dateRange = [date, `${dateTime.getFullYear()}-${Utils.pad(dateTime.getMonth() + 1, 2)}-${Utils.pad(dateTime.getDate(), 2)}`];
    this.monthValue = date;
    this.value.splice(0)
    this.value.push(...dateRange)
    this.$emit('submit', this.value)
    this.$root?.$emit('bv::hide::tooltip')
  }

  dateFormat(classes: any, date: any) {
    if (this.disabledDate) {
      classes.disabled = this.disabledDate(date)
    }

    return classes
  }

  handleClickOutside() {
    this.$root?.$emit('bv::hide::tooltip')
  }
  
  handleIconClick() {
    if (this.valueCounter) {
      this.clearFilter()
    }
  }

  clearSearchQuery() {
    this.searchQuery = ''
  }

  clearTextInput() {
    this.handleInput(null, '');
    this.$emit('submit', '');
  }

  get fieldIcon() {
    if (!this.icon) {
      if (this.valueCounter) {
        return this.XMarkIcon
      } else {
        return this.ChevronDownIcon
      }
    } else {
      return null
    }
  }

  get formattedOptions() {
    return this.options?.filter((el: any) => el?.label?.toLowerCase()?.includes(this.searchQuery?.toLowerCase()))
  }

  get hasDropdown() {
    return this.type === 'select' || this.type === 'multiselect'
  }

  get hasSearch() {
    if (this.hasDropdown) {
      return this.searchable
    }

    return false
  }

  get valueCounter() {
    if (this.hasDropdown) {
      return this.value?.length
    }

    if (this.type === 'range') {
      return this.value?.from || this.value?.to ? 1 : 0
    }

    if (this.type === 'dateRange' || this.type === 'dateMonth') {
      return this.value?.length ? 1 : 0
    }

    return this.value ? 1 : 0
  }

  get dropdownIcon() {
    if (this.icon) {
      return this.icon
    }

    return this.ChevronDownIcon
  }

  handleRangeInput(type: 'from' | 'to', e: any) {
    this.value[type] = e || null
  }

  isCorrectValue(value: any) {
    return !(value === 'undefined' || !value || value?.includes('Y') || value?.includes('_') || value?.includes('M') || value?.includes('D'))
  }

  blockWatcher: boolean = false

  handleDateRangeValue(type: 'from' | 'to', e: any) {
    const dateString = e?.target?.value?.split('-')
    const year = dateString[0]?.substring(0, 4)
    const month = dateString[1]?.substring(0, 2)
    const day = dateString[2]?.substring(0, 2)

    if (this.isCorrectValue(year) && this.isCorrectValue(month) && this.isCorrectValue(day)) {
      const dateType = type == 'from' ? 'startDate' : 'endDate'

      this.$set(this.tempInputDate, dateType, `${year}-${month}-${day}`)

      this.blockWatcher = true
      this.$set(this.tempDate, dateType, moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').toDate())
    }
  }

  handleDateRangeEnter() {
    if (!(!this.tempInputDate?.startDate || !this.tempInputDate?.endDate)) {
      this.$set(this.tempDate, 'startDate', null)
      this.$set(this.tempDate, 'endDate', null)

      this.$emit('submit', this.value)
      this.$root?.$emit('bv::hide::tooltip')
    }

    if (this.tempInputDate?.startDate && this.tempInputDate?.endDate) {
      const startString: any = this.tempInputDate?.startDate?.split('-')
      const startDate: string = `${startString[0]?.substring(0, 4)}-${startString[1]?.substring(0, 2)}-${startString[2]?.substring(0, 2)}`
      const endString: any = this.tempInputDate?.endDate?.split('-')
      const endDate: string = `${endString[0]?.substring(0, 4)}-${endString[1]?.substring(0, 2)}-${endString[2]?.substring(0, 2)}`
  
      this.$set(this.tempDate, 'startDate', moment(startDate).toDate())
      this.$set(this.tempDate, 'endDate', moment(endDate).toDate())
  
      this.$emit('submit', this.value)
      this.$root?.$emit('bv::hide::tooltip')
    }
  }

  handleHidden() {
    this.searchQuery = ''
  }

  handleOpen(type: 'dropdown' | 'range' | 'dateRange' | 'dateMonth') {
    if (type === 'dropdown') {
      (this.$refs?.focusInput as HTMLInputElement)?.focus()
    } else if (type === 'range') {
      ((this.$refs?.focusInput as any)?.$el?.querySelector('input') as HTMLInputElement)?.focus()
    } else if (type === 'dateRange') {
      if (!this.value?.length) {
        this.$set(this.tempInputDate, 'startDate', null);
        this.$set(this.tempInputDate, 'endDate', null);
        this.$set(this.tempDate, 'startDate', null);
        this.$set(this.tempDate, 'endDate', null);
      }
      
      if (this.tempDate?.startDate) {
        this.$set(this.tempInputDate, 'startDate', moment(this.tempDate?.startDate).format('YYYY-MM-DD'));
      } else if (this.value[0]) {
        // this.$set(this.tempInputDate, 'startDate', moment(this.value[0]).format('YYYY-MM-DD'));
        this.$set(this.tempDate, 'startDate', moment(this.value[0]).toDate())
      }

      if (this.tempDate?.endDate) {
        this.$set(this.tempInputDate, 'endDate', moment(this.tempDate?.endDate).format('YYYY-MM-DD'));
      } else if (this.value[1]) {
        // this.$set(this.tempInputDate, 'endDate', moment(this.value[1]).format('YYYY-MM-DD'));
        this.$set(this.tempDate, 'endDate', moment(this.value[1]).toDate())
      }
    } else if (type === 'dateMonth') {
      if (!this.value?.length) {
        this.monthValue = null
      }
    }
  }

  handleBlurEvent(e: any) {
    this.$emit('blur', e)
    this.$emit('submit', e?.target?.value)
  }

  handleEnter(e: any) {
    e?.preventDefault();
    e?.target?.blur();
  }

  handleRangeEnter(e: any) {
    // this.$root?.$emit('bv::hide::tooltip')
    this.handleEnter(e);
    this.$emit('submit', e?.target?.value)
  }

  handleDropdownInput(value: any) {
    if (this.type === 'select') {
      if (this.value === value || this.value?.includes(value)) {
        // const index = this.value.findIndex((el: any) => el === value)
        this.value.splice(0)
      } else {
        this.$set(this.value, 0, value)
      }
      this.$root.$emit('bv::hide::tooltip')
    }

    if (this.type === 'multiselect') {
      if (this.value?.includes(value)) {
        const index = this.value.findIndex((el: any) => el === value)
        this.value.splice(index, 1)
      } else {
        this.value.push(value)
      }
    }

    this.$emit('submit', this.value)

    return
  }

  toggleAll() {
    if (this.value?.length === this.options?.length) {
      this.value.splice(0)
    } else {
      const values = this.options?.map((el: any) => el?.value)
      this.value.splice(0)
      this.value.push(...values)
      this.$emit('submit', this.value)
    }
  }

  clearFilter() {
    if (this.type === 'range') {
      this.$set(this.value, 'from', null)
      this.$set(this.value, 'to', null)
      this.$root?.$emit('bv::hide::tooltip')
      this.$emit('submit', this.value)
    } else {
      if (this.value?.length) {
        this.value.splice(0)
        this.$root?.$emit('bv::hide::tooltip')
        this.$emit('submit', this.value)
      }
    }
  }
  
  @Watch('value', {immediate: true, deep: true }) onValueChange() {
    if (this.hasDropdown) {
      this.modelValue = this.value
      this.displayValue = this.value
    } else {
      if(!this.value) {
        this.modelValue = '';
        this.displayValue = '';
        return;
      }
      if(this.value !== this.modelValue){
        this.modelValue = this.value;
        this.displayValue = this.value;
      }
    }
  }

  @Watch('tempDate', { deep: true }) onTempDateChange() {
    if (this.tempDate?.startDate && this.tempDate?.endDate) {
      const values = [moment(this.tempDate?.startDate).format('YYYY-MM-DD'), moment(this.tempDate?.endDate).format('YYYY-MM-DD')]
      this.value?.splice(0)
      this.value.push(...values)
      this.$set(this.tempInputDate, 'startDate', moment(this.tempDate?.startDate).format('YYYY-MM-DD'))
      this.$set(this.tempInputDate, 'endDate', moment(this.tempDate?.endDate).format('YYYY-MM-DD'))
      if (!this.blockWatcher) {
        this.$root?.$emit('bv::hide::tooltip')
        this.$emit('submit', this.value)
      }
    }

    this.blockWatcher = false
  }
  
  @Emit('input')
  handleInput(event: any, value: string | number): string | number {
    this.modelValue = value;
    return value;
  }

  created() {
    this.modelValue = this.value;
  }
}
