




































import Vue from 'vue';
import Component from 'vue-class-component';
import LogoUploader from "@/components/SygniLogoUploader.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniTextarea from "@/components/inputs/SygniTextArea.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import { mapGetters } from 'vuex';
import { Attendant, LegalEntityData } from '../store/types';
import { CheckboxOption } from '@/store/types';
import { Watch } from 'vue-property-decorator';
import Utils from '@/modules/shared/utils/utils';
import { UploadFileDTO } from '@/modules/genprox/models/Shared';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import GenproxButton from '@/components/buttons/GenproxButton.vue';

@Component({
  components: { LogoUploader, SygniSelect, SygniInput, SygniTextarea, SygniRoundedButton, UserPresentation, SygniContainerTitle, SygniLoader, GenproxButton },
  computed: {
    ...mapGetters('whistleblower', {
      attendants: 'getAttendants',
      legalEntityData: 'getLegalEntityData',
    })
  }
})
export default class SettingsPanel extends Vue {
  attendant: String = null;
  attendants!: Array<Attendant>;
  legalEntityData!: LegalEntityData;
  hasChanges: boolean = false;
  isLoading: boolean = false;
  logo: File = null;
  attendantDetails: {
    name: String,
    role?: String,
    email: String,
    linkedin: String,
    phone: String,
    photo?: String,
  } = {
    name: '',
    role: '',
    email: '',
    linkedin: '',
    phone: '',
    photo: '',
  }

  clearAttendantDetails() {
    this.attendantDetails.name = '';
    this.attendantDetails.role = '';
    this.attendantDetails.email = '';
    this.attendantDetails.linkedin = '';
    this.attendantDetails.phone = '';
    this.attendantDetails.photo = '';
    this.logo = null;
  }

  setAttendantDetails() {
    if(this.selectedAttendant) {
      this.attendant = this.selectedAttendant.id;
      this.attendantDetails.email = this.selectedAttendant.email;
      this.attendantDetails.name = this.selectedAttendant.name;
      this.attendantDetails.linkedin = this.selectedAttendant.linkedin;
      this.attendantDetails.phone = this.selectedAttendant.phone;
      this.attendantDetails.photo = this.selectedAttendant.photo;

      if(this.selectedAttendant.role.length) {
        this.attendantDetails.role = this.selectedAttendant.role.join(', ');
      } else {
        this.attendantDetails.role = '';
      }
    }
  }

  handleFieldChange() {
    this.hasChanges = true;
  }

  setLogo(event: File) {
    this.handleFieldChange();
    this.legalEntityData.logo = null;
    this.logo = event;
  }

  deleteLogo() {
    this.handleFieldChange();
    this.legalEntityData.logo = null;
    this.logo = null;
  }

  async saveChanges() {
    if(!this.hasChanges) return;

    this.isLoading = true;

    const legalEntityData = {
      name: this.legalEntityData.name,
      welcomeMessage: this.legalEntityData.welcomeMessage,
    };
    const editLegalEntity = this.$store.dispatch('whistleblower/editLegalEntity', legalEntityData)

    let setAttendant = undefined;
    let logo = undefined;

    if (this.attendant) {
      setAttendant = this.$store.dispatch('whistleblower/setAttendant', this.attendant);
    }

    if (this.logo && !this.legalEntityData.logo) {
      const payload: UploadFileDTO = {
        config: Utils.getUploadFileConfig(),
        file: this.logo
      }
      logo = this.$store.dispatch('whistleblower/uploadLegalEntityLogo', payload)
    } else if (!this.logo && !this.legalEntityData.logo) {
      logo = this.$store.dispatch('whistleblower/deleteLegalEntityLogo');
    }

    try {
      await Promise.all([editLegalEntity, setAttendant, logo]);
      await this.loadSettings();
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Settings updated.'
      });
    } catch(e) {
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: 'Something went wrong, please try again later.'
      });
    }

    this.isLoading = false;
  }

  get attendantLabel() {
    return (this.selectedAttendant) ? 'CHANGE COMPLIANCE OFFICER' : 'CHOOSE COMPLIANCE OFFICER'
  }

  get attendantOptions(): Array<CheckboxOption> {
    if(!this.attendants) return [];

    return this.attendants.map((el: any) => {
      return { label: el.name, value: el.id }
    })
  }

  get selectedAttendant(): Attendant | null {
    if(!this.attendant) return null;

    return this.attendants.find(el => el.id == this.attendant);
  }

  get attendantFieldsPlaceholder() {
    return !this.attendant ? 'First choose attendant' : '';
  }

  async loadAttendant() {
    const attendant = await this.$store.dispatch('whistleblower/getAttendant');

    this.attendant = attendant.id;
  }

  async loadSettings() {
    await this.$store.dispatch('whistleblower/getAttendants');
    await this.loadAttendant();
  }

  async beforeMount() {
    await this.loadSettings();
  }

  @Watch('attendant') onAttendantChange() {
    if(!this.attendant) {
      this.clearAttendantDetails();
    } else {
      this.setAttendantDetails();
    }
  }
}
