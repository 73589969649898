var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.handleClickOutside,
      expression: "handleClickOutside"
    }],
    key: "gn-dropdown-menu-".concat(_vm.reRender),
    class: ['gn-dropdown-menu', "gn-dropdown-menu--".concat(_vm.size)],
    attrs: {
      "id": "".concat(_vm.componentId)
    }
  }, [_vm._t("default", function () {
    return [_vm._v(_vm._s(_vm.label))];
  }), !_vm.isDisabled ? _c('b-tooltip', {
    key: _vm.tooltipTarget,
    attrs: {
      "triggers": "click",
      "placement": _vm.placement,
      "custom-class": _vm.customClass,
      "boundary": _vm.boundary,
      "boundary-padding": "16",
      "target": _vm.tooltipTarget
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      },
      "hidden": function hidden($event) {
        return _vm.$emit('hidden');
      },
      "shown": function shown($event) {
        return _vm.$emit('open');
      },
      "hide": function hide($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    class: ['gn-dropdown-menu', _vm.additionalDropdownClass],
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    staticClass: "gn-dropdown-menu__wrapper"
  }, [_c('div', {
    staticClass: "gn-dropdown-menu__inner"
  }, [_vm._t("beforeMenuList"), _c('simplebar', {
    staticClass: "gn-dropdown-menu__items"
  }, _vm._l(_vm.options, function (option) {
    var _vm$selectedOptions;

    return _c('div', {
      key: option === null || option === void 0 ? void 0 : option.value,
      class: ['gn-dropdown-menu__link', _vm.isSelected(option) ? 'selected' : '', (option === null || option === void 0 ? void 0 : option.value) === _vm.activeOption ? 'active' : '', option !== null && option !== void 0 && option.disabled ? 'disabled' : ''],
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.handleInput(option === null || option === void 0 ? void 0 : option.value, option === null || option === void 0 ? void 0 : option.disabled);
        }
      }
    }, [_vm._t("optionPrefix", null, {
      "rowData": option
    }), _c('span', [_vm._v(_vm._s(option === null || option === void 0 ? void 0 : option.label))]), _vm.showCheckIcon && (_vm$selectedOptions = _vm.selectedOptions) !== null && _vm$selectedOptions !== void 0 && _vm$selectedOptions.includes(option === null || option === void 0 ? void 0 : option.value) ? _c('CheckIcon', {
      staticClass: "check-icon"
    }) : _vm._e()], 2);
  }), 0), _vm._t("afterMenuList")], 2)])])]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }