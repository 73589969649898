























import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop, Watch} from "vue-property-decorator";
import { SelectOption } from '@/store/types';
import simplebar from 'simplebar-vue';
import { BTooltip } from 'bootstrap-vue';
import 'simplebar/dist/simplebar.min.css';
import { CheckIcon } from '@/assets/icons/heroicons/heroicons';

@Component({
  components: { simplebar, BTooltip, CheckIcon },
})
export default class GenproxDropdownMenu extends Vue {
  @Prop({ default: '' }) additionalDropdownClass: string
  @Prop({ default: 'normal' }) size: 'normal' | 'small'
  @Prop({ default: 'bottomleft' }) placement: string
  @Prop({ default: [] }) selectedOptions: string[];
  @Prop({ default: true }) showCheckIcon: boolean;
  @Prop({ default: [] }) options!: SelectOption[];
  @Prop({ default: 'viewport' }) boundary: string;
  @Prop({ default: null }) activeOption!: String;
  @Prop({ default: null }) componentId: string;
  @Prop({ default: true }) optionsChangeEvent: boolean;
  @Prop({ default: 'plain' }) customClass: string;
  @Prop({ default: false }) isDisabled: boolean
  @Prop() label!: String;
  
  reRender: number = 0;

  get tooltipTarget() {
    return `${this.componentId}`
  }

  isSelected(option: any) {
    return this.selectedOptions?.includes(option?.value)
  }

  handleInput(value: string, isDisabled: boolean = false): void {
    if (isDisabled !== undefined && isDisabled) return;

    this.$emit('input', value)
  }

  handleClickOutside() {
    this.$root.$emit('bv::hide::tooltip')
  }

  @Watch('options', { deep: true }) onMenusChange() {
    if (this.optionsChangeEvent) {
      this.reRender++
    }
  }

  @Watch('tooltipTarget', { deep: true }) onTooltipTargetChange() {
    this.reRender++
  }
}
