





































































import Vue from 'vue';
import Component from 'vue-class-component'
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniSquareButton from '@/components/buttons/SygniSquareButton.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import { CategoryItem } from '../store/types';
import GenproxButton from '@/components/buttons/GenproxButton.vue';

@Component({
  components: { SygniContainerTitle, SygniRoundedButton, SygniSquareButton, SygniInput, SygniLoader, GenproxButton },
})
export default class CategoriesPanel extends Vue {
  isLoading: boolean = false;
  newCategory: string = '';

  get categories() {
    return this.$store.getters['whistleblower/getSettingCategories'];
  }

  get requiredCategories() {
    return this.categories.filter((el: any) => el.default == true);
  }

  get customCategories() {
    return this.categories.filter((el: any) => el.default != true);
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isWhistleblowerComplianceOfficer() {
    return this.activeUserData.role.whistleblower?.includes('compliance-officer');
  }

  viewCategory(category: string) {
    this.$emit('viewCategory', category);
  }

  async addNewCustomCategory(name: string) {
    if(name.length) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('whistleblower/postCategory', name);
        this.newCategory = '';
        await this.refreshCategories();
      } catch(error) {
        const errorMessage = this.$options.filters.errorHandler(error);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        });
      }
      this.isLoading = false;
    }
  }

  async deleteCustomCategory(category: CategoryItem) {
    this.isLoading = true;
    try {
      await this.$store.dispatch('whistleblower/deleteCategory', category.category);
      await this.refreshCategories();
    } catch(error) {
      const errorMessage = this.$options.filters.errorHandler(error);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      });
    }
    this.isLoading = false;
  }

  async refreshCategories() {
    await this.$store.dispatch('whistleblower/getSettingCategories')
  }

  async beforeMount() {
    await this.refreshCategories();
  }
}
