var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settings-panel"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "settings-panel__container"
  }, [_c('div', {
    staticClass: "settings-panel__column"
  }, [_c('sygni-container-title', {
    staticClass: "settings-panel__header",
    attrs: {
      "inline": true
    }
  }, [_vm._v("Company settings")]), _c('div', {
    staticClass: "settings-panel__logo-uploader"
  }, [_c('logo-uploader', {
    attrs: {
      "note": "min 200 x 200px",
      "tempLogo": _vm.legalEntityData.logo,
      "title": "Upload new image"
    },
    on: {
      "input": function input($event) {
        return _vm.setLogo($event);
      },
      "delete": _vm.deleteLogo
    }
  })], 1), _c('div', {
    staticClass: "settings-panel__form"
  }, [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": "COMPANY NAME"
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.legalEntityData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.legalEntityData, "name", $$v);
      },
      expression: "legalEntityData.name"
    }
  }), _c('sygni-textarea', {
    staticClass: "secondary",
    attrs: {
      "label": "WELCOME MESSAGE",
      "placeholder": "Type here your welcome message",
      "maxLength": 2000
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.legalEntityData.welcomeMessage,
      callback: function callback($$v) {
        _vm.$set(_vm.legalEntityData, "welcomeMessage", $$v);
      },
      expression: "legalEntityData.welcomeMessage"
    }
  })], 1), _c('div', {
    staticClass: "settings-panel__actions"
  }, [_c('GenproxButton', {
    attrs: {
      "isDisabled": true,
      "outline": true
    }
  }, [_vm._v("Preview")]), _c('GenproxButton', {
    staticClass: "filled gn-primary",
    on: {
      "click": function click($event) {
        return _vm.saveChanges();
      }
    }
  }, [_vm._v("Save changes")])], 1)], 1), _c('div', {
    staticClass: "settings-panel__column"
  }, [_c('sygni-container-title', {
    staticClass: "settings-panel__header",
    attrs: {
      "inline": true
    }
  }, [_vm._v("Compliance Officer")]), _c('div', {
    staticClass: "settings-panel__uploader"
  }, [_c('user-presentation', {
    attrs: {
      "name": _vm.attendantDetails.name ? _vm.attendantDetails.name : 'Name Surname',
      "description": _vm.attendantDetails.name ? _vm.$options.filters.capitalizeFirstLetter(_vm.attendantDetails.role) : 'Role',
      "image": _vm.attendantDetails.photo
    }
  })], 1), _c('div', {
    staticClass: "settings-panel__form"
  }, [_c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "label": _vm.attendantLabel,
      "options": _vm.attendantOptions
    },
    on: {
      "input": _vm.handleFieldChange
    },
    model: {
      value: _vm.attendant,
      callback: function callback($$v) {
        _vm.attendant = $$v;
      },
      expression: "attendant"
    }
  })], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }