




















import Component, { mixins } from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniMultiSelect from "@/components/inputs/SygniMultiSelect.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniButtonsGroup from "@/components/layout/SygniButtonsGroup.vue";
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import {Filter, FilterFunctions, FilterMixin, FilterOperators} from "@/shared/mixins/FilterMixin";
import SygniCheckableButton from "@/components/buttons/SygniCheckableButton.vue";
import GenproxButton from '@/components/buttons/GenproxButton.vue';
import GenproxField from '@/components/inputs/GenproxField.vue';

@Component({
  components: { SygniCheckableButton, SygniButtonsGroup, SygniDatePicker, SygniContainerTitle, SygniRoundedButton, SygniSelect, SygniInput, SygniMultiSelect, SygniLinkButton, GenproxButton, GenproxField },
})
export default class WhistleblowerFilters extends mixins (FilterMixin) {
  itemsUrl: string = 'whistleblower/case';

  orFilters: Record<string, Filter> = {
    token: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
  }

  filters: Record<string, Filter> = {
    category: { value: [], getQueryValue: FilterFunctions.string, operator: FilterOperators.eq, type: 'string' },
    createdAt: { value: [], getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    status: { value: [], getQueryValue: FilterFunctions.array, operator: FilterOperators.in, type: 'string' },
  };
  selectedCaseStatusOptions: Array<any> = [];

  get categories() {
    return this.$store.getters['whistleblower/getCategories'];
  }

  get caseStatusOptions() {
    return this.$store.getters['whistleblower/getStatuses'];
  }

  updateStatuses(statuses: Array<string>) {
    this.filters.status.value = statuses;
  }

  setStatusFilter(status: string) {
    this.clearAll();
    this.filters.category.value = status;
    this.applyFilters();
  }

  toggleAllStatuses() {
    if(this.selectedCaseStatusOptions.length < this.caseStatusOptions.length) {
      const statuses = this.caseStatusOptions.map((el: any) => el.value);
      this.selectedCaseStatusOptions = statuses;
      this.updateStatuses(statuses);
    } else {
      this.selectedCaseStatusOptions = [];
      this.updateStatuses([]);
    }
  }
}
