










































import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import { InformationCircleIcon } from '@/assets/icons/heroicons/heroicons';

@Component({
  components: { simplebar, InformationCircleIcon }
})
export default class SygniTabs extends Vue {
  @Prop({default: false}) disableSlots: boolean;
  @Prop({default: true}) legacyStyling: boolean;
  @Prop() defaultOption: string;
  @Prop() options: Array<any>;

  selectedOptionKey: string = '';

  isSelectedOption(key: string) {
    return this.selectedOptionKey == key;
  }

  setOption(key: string) {
    this.$emit('click', key);
    this.selectedOptionKey = key;
  }

  mounted() {
    if(this.options.length) {
      this.selectedOptionKey = this.defaultOption ? this.defaultOption : this.options[0].key;
    } else {
      this.selectedOptionKey = this.defaultOption ? this.defaultOption : '';
    }
  }
}

