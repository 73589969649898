var render = function () {
  var _vm$value, _vm$value2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.handleClickOutside,
      expression: "handleClickOutside"
    }],
    class: ['gn-field', _vm.hasDropdown ? 'gn-field--dropdown' : '', _vm.valueCounter ? 'gn-field--used' : '', _vm.isDisabled ? 'gn-field--disabled' : '', _vm.type === 'text' || _vm.type === 'email' ? 'gn-field--text' : ''],
    attrs: {
      "tabindex": "-1"
    }
  }, [_c('div', {
    staticClass: "gn-field__input"
  }, [_vm.type === 'text' || _vm.type === 'email' ? [_c('input', {
    attrs: {
      "type": _vm.type,
      "placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function input($event) {
        return _vm.handleInput($event, $event.target.value);
      },
      "blur": _vm.handleBlurEvent,
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.handleEnter.apply(null, arguments);
      }
    }
  }), _vm.value ? _c('div', {
    staticClass: "gn-field__search-clear",
    on: {
      "click": _vm.clearTextInput
    }
  }, [_c('XMarkIcon')], 1) : _vm._e()] : _vm._e(), _vm.type === 'range' ? [_c('button', {
    staticClass: "gn-field__btn",
    attrs: {
      "id": _vm.componentId
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.label))]), _vm.valueCounter ? _c('div', {
    staticClass: "gn-field__counter",
    attrs: {
      "arria-hidden": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.valueCounter) + " ")]) : _vm._e(), !_vm.icon ? _c('div', {
    class: ['gn-field__icon', _vm.valueCounter ? 'selected' : ''],
    attrs: {
      "aria-hidden": "true",
      "data-slot": "icon"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleIconClick.apply(null, arguments);
      }
    }
  }, [_c(_vm.fieldIcon, {
    tag: "component"
  })], 1) : _vm._e()]), !_vm.isDisabled ? _c('b-tooltip', {
    attrs: {
      "customClass": "plain gn-field-dropdown gn-field-range",
      "placement": "bottom",
      "boundary": "body",
      "triggers": "click",
      "target": _vm.componentId
    },
    on: {
      "shown": function shown($event) {
        return _vm.handleOpen('range');
      },
      "hidden": _vm.handleRangeEnter
    }
  }, [_c('div', {
    staticClass: "gn-field-range__inner"
  }, [_c('div', {
    staticClass: "gn-field-range__container",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('sygni-input', {
    ref: "focusInput",
    attrs: {
      "label": "",
      "value": (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.from,
      "displayFormat": 'currency',
      "legacyStyling": false,
      "placeholder": "Min"
    },
    on: {
      "input": function input($event) {
        return _vm.handleRangeInput('from', $event);
      },
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.handleRangeEnter.apply(null, arguments);
      }
    }
  }), _c('span', [_vm._v(" - ")]), _c('sygni-input', {
    attrs: {
      "label": "",
      "value": (_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.to,
      "displayFormat": 'currency',
      "legacyStyling": false,
      "placeholder": "Max"
    },
    on: {
      "input": function input($event) {
        return _vm.handleRangeInput('to', $event);
      },
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.handleRangeEnter.apply(null, arguments);
      }
    }
  })], 1)])]) : _vm._e()] : _vm._e(), _vm.type === 'dateRange' || _vm.type === 'dateMonth' ? [_c('button', {
    staticClass: "gn-field__btn",
    attrs: {
      "id": _vm.componentId
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.label))]), _vm.valueCounter ? _c('div', {
    staticClass: "gn-field__counter",
    attrs: {
      "arria-hidden": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.valueCounter) + " ")]) : _vm._e(), !_vm.icon ? _c('div', {
    class: ['gn-field__icon', _vm.valueCounter ? 'selected' : ''],
    attrs: {
      "aria-hidden": "true",
      "data-slot": "icon"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleIconClick.apply(null, arguments);
      }
    }
  }, [_c(_vm.fieldIcon, {
    tag: "component"
  })], 1) : _vm._e()]), _vm.type === 'dateRange' ? [!_vm.isDisabled ? _c('b-tooltip', {
    attrs: {
      "customClass": "plain gn-field-dropdown gn-field-date-range",
      "placement": "bottom",
      "boundary": "body",
      "triggers": "click",
      "target": _vm.componentId
    },
    on: {
      "show": function show($event) {
        return _vm.handleOpen('dateRange');
      },
      "hidden": _vm.handleRangeEnter
    }
  }, [_c('div', {
    staticClass: "gn-field-date-range__inner",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('div', {
    staticClass: "gn-field-date-range__container"
  }, [_c('div', {
    staticClass: "gn-field-date-range__calendar"
  }, [_c('date-range-picker', {
    ref: "picker",
    attrs: {
      "opens": "inline",
      "singleDatePicker": "range",
      "timePicker": false,
      "showWeekNumbers": false,
      "showDropdowns": false,
      "ranges": false,
      "linkedCalendars": false,
      "dateFormat": _vm.dateFormat,
      "autoApply": true,
      "localeData": {
        firstDay: 1
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "gn-field-date-range__footer"
        }, [_c('sygni-input', {
          ref: "focusInput",
          staticClass: "text-center",
          attrs: {
            "mask": "date",
            "placeholder": "YYYY-MM-DD",
            "legacyStyling": false
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.handleDateRangeEnter.apply(null, arguments);
            },
            "blur": function blur($event) {
              return _vm.handleDateRangeValue('from', $event);
            }
          },
          model: {
            value: _vm.tempInputDate.startDate,
            callback: function callback($$v) {
              _vm.$set(_vm.tempInputDate, "startDate", $$v);
            },
            expression: "tempInputDate.startDate"
          }
        }), _c('span', [_vm._v(" - ")]), _c('sygni-input', {
          staticClass: "text-center",
          attrs: {
            "mask": "date",
            "placeholder": "YYYY-MM-DD",
            "legacyStyling": false
          },
          on: {
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.handleDateRangeEnter.apply(null, arguments);
            },
            "blur": function blur($event) {
              return _vm.handleDateRangeValue('to', $event);
            }
          },
          model: {
            value: _vm.tempInputDate.endDate,
            callback: function callback($$v) {
              _vm.$set(_vm.tempInputDate, "endDate", $$v);
            },
            expression: "tempInputDate.endDate"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3427685422),
    model: {
      value: _vm.tempDate,
      callback: function callback($$v) {
        _vm.tempDate = $$v;
      },
      expression: "tempDate"
    }
  })], 1)])])]) : _vm._e()] : _vm._e(), _vm.type === 'dateMonth' ? [!_vm.isDisabled ? _c('b-tooltip', {
    attrs: {
      "customClass": "plain gn-field-month-range",
      "placement": "bottom",
      "boundary": "body",
      "triggers": "click",
      "target": _vm.componentId
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
      },
      "show": function show($event) {
        return _vm.handleOpen('dateMonth');
      }
    }
  }, [_c('div', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('date-picker', {
    staticClass: "gn-field-date-picker",
    attrs: {
      "appendToBody": false,
      "value": _vm.monthValue,
      "type": "month",
      "disabled-date": _vm.disabledDate,
      "inline": true,
      "valueType": "format",
      "format": "YYYY-MM-DD"
    },
    on: {
      "input": _vm.setMonthRange
    }
  })], 1)]) : _vm._e()] : _vm._e()] : _vm._e(), _vm.hasDropdown ? [_c('GenproxDropdownMenu', {
    attrs: {
      "isDisabled": _vm.isDisabled,
      "componentId": _vm.componentId,
      "customClass": "plain gn-field-dropdown",
      "boundary": _vm.boundary,
      "placement": _vm.placement,
      "optionsChangeEvent": false,
      "selectedOptions": _vm.value,
      "options": _vm.formattedOptions,
      "showCheckIcon": false
    },
    on: {
      "input": _vm.handleDropdownInput,
      "hidden": _vm.handleHidden,
      "open": function open($event) {
        return _vm.handleOpen('dropdown');
      }
    },
    scopedSlots: _vm._u([_vm.hasSearch ? {
      key: "beforeMenuList",
      fn: function fn() {
        return [_c('div', {
          staticClass: "gn-field-dropdown__search",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.searchQuery,
            expression: "searchQuery"
          }],
          ref: "focusInput",
          attrs: {
            "type": "text",
            "placeholder": "Search"
          },
          domProps: {
            "value": _vm.searchQuery
          },
          on: {
            "input": [function ($event) {
              if ($event.target.composing) { return; }
              _vm.searchQuery = $event.target.value;
            }, function ($event) {
              $event.preventDefault();
            }],
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }), _vm.searchQuery ? _c('div', {
          staticClass: "gn-field-dropdown__search-clear",
          on: {
            "click": _vm.clearSearchQuery
          }
        }, [_c('XMarkIcon')], 1) : _vm._e(), _c('div', {
          staticClass: "gn-field-dropdown__icon"
        }, [_c('MagnyfyingGlassIcon')], 1)])];
      },
      proxy: true
    } : null, _vm.type === 'multiselect' ? {
      key: "optionPrefix",
      fn: function fn(_ref) {
        var _vm$value3;

        var rowData = _ref.rowData;
        return [_c('sygni-checkbox', {
          attrs: {
            "value": (_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : _vm$value3.includes(rowData === null || rowData === void 0 ? void 0 : rowData.value),
            "legacyStyling": false,
            "label": ""
          }
        })];
      }
    } : null, {
      key: "afterMenuList",
      fn: function fn() {
        var _vm$value4, _vm$formattedOptions, _vm$value5;

        return [_c('div', {
          staticClass: "gn-field-dropdown__options",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_vm.type === 'multiselect' && !_vm.searchQuery ? _c('a', {
          staticClass: "gn-link",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
              return _vm.toggleAll.apply(null, arguments);
            }
          }
        }, [_vm._v(_vm._s(((_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : _vm$value4.length) === ((_vm$formattedOptions = _vm.formattedOptions) === null || _vm$formattedOptions === void 0 ? void 0 : _vm$formattedOptions.length) ? 'Deselect' : 'Select') + " all")]) : _vm._e(), _c('a', {
          class: ['gn-link ml-auto', !((_vm$value5 = _vm.value) !== null && _vm$value5 !== void 0 && _vm$value5.length) ? 'inactive' : ''],
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
              return _vm.clearFilter.apply(null, arguments);
            }
          }
        }, [_vm._v("Clear")])])];
      },
      proxy: true
    }], null, true)
  }, [_c('button', {
    staticClass: "gn-field__btn",
    on: {
      "click": function click($event) {
        $event.preventDefault();
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.label))]), _vm.valueCounter ? _c('div', {
    staticClass: "gn-field__counter",
    attrs: {
      "arria-hidden": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.valueCounter) + " ")]) : _vm._e(), !_vm.icon ? _c('div', {
    class: ['gn-field__icon', _vm.valueCounter ? 'selected' : ''],
    attrs: {
      "aria-hidden": "true",
      "data-slot": "icon"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleIconClick.apply(null, arguments);
      }
    }
  }, [_c(_vm.fieldIcon, {
    tag: "component"
  })], 1) : _vm._e()])])] : _vm._e(), _vm.icon ? [_vm.icon ? _c('div', {
    staticClass: "gn-field__icon",
    attrs: {
      "aria-hidden": "true",
      "data-slot": "icon"
    }
  }, [_c(_vm.icon, {
    tag: "component"
  })], 1) : _vm._e()] : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }