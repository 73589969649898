var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['sygni-tabs', !_vm.legacyStyling ? 'tw-theme' : '']
  }, [_c('simplebar', {
    staticClass: "sygni-tabs__wrapper"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "sygni-tabs__inner"
  }, [_c('div', {
    staticClass: "sygni-tabs__container"
  }, [_vm._l(_vm.options, function (option) {
    return _c('div', {
      key: option.key,
      class: ['sygni-tabs__option', _vm.isSelectedOption(option.key) ? 'sygni-tabs__option--active' : '', option !== null && option !== void 0 && option.hasErrors ? 'sygni-tabs__option--errors' : ''],
      attrs: {
        "id": (option === null || option === void 0 ? void 0 : option.id) || null
      },
      on: {
        "click": function click($event) {
          return _vm.setOption(option.key);
        }
      }
    }, [_c('span', [_vm._v(_vm._s(option.label))]), option !== null && option !== void 0 && option.hasErrors ? _c('span', {
      staticClass: "error-icon"
    }, [_c('InformationCircleIcon', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.right",
        value: 'Has errors',
        expression: "'Has errors'",
        modifiers: {
          "hover": true,
          "right": true
        }
      }]
    })], 1) : _vm._e()]);
  }), !_vm.legacyStyling ? _c('div', {
    staticClass: "sygni-tabs__option--last"
  }) : _vm._e(), _c('div', {
    staticClass: "sygni-tabs__actions"
  }, [_vm._t("actions")], 2)], 2)])])])])]), _vm._t("beforeContent"), _c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [!_vm.disableSlots ? _c('div', {
    ref: "sygniBody",
    staticClass: "sygni-tabs__body"
  }, _vm._l(_vm.options, function (option) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isSelectedOption(option.key),
        expression: "isSelectedOption(option.key)"
      }],
      key: option.key,
      staticClass: "sygni-tabs__content"
    }, [_vm._t(option.key)], 2);
  }), 0) : _vm._e()])])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }