var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hero-filters"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "hero-filters__container"
  }, [_c('div', {
    staticClass: "hero-filters__fields"
  }, [_c('GenproxField', {
    staticClass: "search",
    attrs: {
      "componentId": "searchInput",
      "icon": _vm.MagnyfyingGlassIcon,
      "placeholder": "Search"
    },
    on: {
      "submit": function submit($event) {
        return _vm.handleSearch($event, ['token']);
      }
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Date",
      "componentId": "date",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.createdAt.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.createdAt, "value", $$v);
      },
      expression: "filters.createdAt.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Category",
      "options": _vm.categories,
      "componentId": "category",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.category.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.category, "value", $$v);
      },
      expression: "filters.category.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Case Status",
      "options": _vm.caseStatusOptions,
      "componentId": "status",
      "type": "multiselect"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.status.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.status, "value", $$v);
      },
      expression: "filters.status.value"
    }
  })], 1), _c('div', {
    staticClass: "hero-filters__clear-wrapper"
  }, [_c('a', {
    class: ['hero-filters__clear gn-link', _vm.filterQuery || _vm.orFilterQuery ? 'enabled' : ''],
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clearAll.apply(null, arguments);
      }
    }
  }, [_vm._v("Clear all")])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }