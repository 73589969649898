var render = function () {
  var _vm$formattedItems;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_vm$formattedItems = _vm.formattedItems) !== null && _vm$formattedItems !== void 0 && _vm$formattedItems.length ? _c('div', {
    staticClass: "gn-breadcrumbs"
  }, [_c('ul', {
    staticClass: "gn-breadcrumbs__list"
  }, _vm._l(_vm.formattedItems, function (item, index) {
    var _vm$formattedItems2;

    return _c('li', {
      key: item.label,
      staticClass: "gn-breadcrumbs__item"
    }, [item.href ? _c('router-link', {
      attrs: {
        "to": item.href
      }
    }, [_vm._v(_vm._s(item.text !== ':title' ? item.text : ''))]) : _vm._e(), item.href && index < ((_vm$formattedItems2 = _vm.formattedItems) === null || _vm$formattedItems2 === void 0 ? void 0 : _vm$formattedItems2.length) - 1 ? _c('span', {
      staticClass: "gn-breadcrumbs__divider"
    }, [_vm._v("/")]) : _vm._e(), !item.href ? _c('span', {
      staticClass: "gn-breadcrumbs__item-span"
    }, [_vm._v(_vm._s(item.text !== ':title' ? item.text : ''))]) : _vm._e()], 1);
  }), 0)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }