
































































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniSquareButton from '@/components/buttons/SygniSquareButton.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniDatePicker from '@/components/inputs/SygniDatePicker.vue';
import SygniMultiSelect from '@/components/inputs/SygniMultiSelect.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniButtonsGroup from "@/components/layout/SygniButtonsGroup.vue";
import SygniCheckableButton from "@/components/buttons/SygniCheckableButton.vue";
import WhistleblowerFilters from '../components/WhistleblowerFilters.vue';
import WhistleblowerTable from '../components/WhistleblowerTable.vue';
import SygniTabs from '@/components/layout/SygniTabs.vue';
import {TableData} from "@/modules/genprox/modules/profile/store/types";
import {mapState} from "vuex";
import CaseModal from '../components/CaseModal.vue';
import SettingsPanel from '../components/SettingsPanel.vue';
import CategoriesPanel from '../components/CategoriesPanel.vue';

import CreateCaseModal from '../components/CreateCaseModal.vue';
import { CaseItem, LegalEntityData } from '../store/types';
import GenproxPageHeading from '@/components/layout/GenproxPageHeading.vue';
import _ from 'lodash';
import GenproxButton from '@/components/buttons/GenproxButton.vue';

@Component({
  components: { SygniRoundedButton, SygniSquareButton, SygniLinkButton, SygniInput, SygniDatePicker, SygniSelect, SygniMultiSelect, SygniButtonsGroup, SygniCheckableButton, WhistleblowerFilters, WhistleblowerTable, SygniTabs, CaseModal, CreateCaseModal, SettingsPanel, CategoriesPanel, GenproxPageHeading, GenproxButton },
  computed: mapState('whistleblower', {
    casesTable: (state: any) => state.casesTable,
    isBusy: (state: any) => state.casesTableIsBusy,
    perPage: (state: any) => state.casesTable.perPage,
    legalEntityData: (state: any) => state.legalEntityData
  })
})
export default class whistleblowerModule extends Vue {
  showInstructions: boolean = true;
  casesTable!: TableData<any>;
  showCaseModal: boolean = false;
  showCreateCaseModal: boolean = false;
  fundToken: string = '';
  legalEntityData!: LegalEntityData;
  filtersQueryString: string = '';
  openedCaseId: string = '';
  isCaseModalLoading: boolean = false;
  selectedTab: string = null;

  tabs: Array<any> = [];

  get pageTitle() {
    return this.selectedTab === 'settings' ? 'Settings' : this.selectedTab === 'categories' ? 'Categories' : 'Cases'
  }

  selectTab(value: string) {
    this.selectedTab = value;
  }

  viewCategory(category: string) {
    (this.$refs.whistleblowerFilters as WhistleblowerFilters).setStatusFilter(category);
    (this as any).$scrollTo(((this.$refs.sygniTabs as SygniTabs).$el as HTMLElement));
    (this.$refs.sygniTabs as SygniTabs).setOption('cases')
  }

  goToSettings() {
    (this as any).$scrollTo(((this.$refs.sygniTabs as SygniTabs).$el as HTMLElement));
    (this.$refs.sygniTabs as SygniTabs).setOption('settings')
  }

  toggleInstructions(): void {
    this.showInstructions = !this.showInstructions;
  }

  copyFundToken() {
    this.$copyText(this.fundToken).then(() => {
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Text copied'
      });
    }, () => {
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'error',
        text: 'Text cannot be copied'
      });
    });
  }

  async openCaseModal(caseItem: CaseItem) {
    this.isCaseModalLoading = true;
    this.openedCaseId = caseItem.id;
  }

  closeCaseModal() {
    this.openedCaseId = '';
  }

  enableCreateCaseModal() {
    this.showCreateCaseModal = true;
    const appContentDiv: HTMLDivElement = (this.$refs['createCaseModal'] as any).$el as HTMLDivElement | undefined;
    this.$nextTick(() => {
      appContentDiv?.querySelector('.create-case-modal__inner').scrollTo({top: 0});
    })
  }

  closeCreateCaseModal() {
    this.showCreateCaseModal = false;
    // refresh table items
    (this.$refs.whistleblowerTable as WhistleblowerTable).onFiltersChange('');
  }

  get instructionsText(): string {
    return this.showInstructions ? 'Hide instructions' : 'Show instructions';
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isWhistleblowerAdmin() {
    return this.activeUserData.role.whistleblower?.includes('admin');
  }

  get isWhistleblowerComplianceOfficer() {
    return this.activeUserData.role.whistleblower?.includes('compliance-officer');
  }

  get tabsDefaultOption() {
    if(this.isWhistleblowerAdmin && !this.isWhistleblowerComplianceOfficer) {
      return 'settings';
    }

    return 'cases';
  }

  applyFiltersEvent(queryString: string) {
    this.filtersQueryString = queryString;
    (this.$refs.whistleblowerTable as WhistleblowerTable).onFiltersChange(queryString);
  }

  refreshCases() {
    (this.$refs.whistleblowerTable as WhistleblowerTable).onFiltersChange(this.filtersQueryString);
  }

  async loadLegalEntityData() {
    const response = await this.$store.dispatch('whistleblower/getLegalEntityData');

    this.fundToken = response.token;
  }

  async loadData() {
    this.$store.dispatch('whistleblower/getCases');
    await this.$store.dispatch('whistleblower/getCategories');
  }

  async beforeMount() {
    this.tabs = [];
    try {
      await this.loadLegalEntityData();
  
      if(this.isWhistleblowerComplianceOfficer) {
        await this.loadData(); 
        this.tabs.push({ key: 'cases', label: 'Cases', });
        this.tabs.push({ key: 'categories', label: 'Categories' });
      }
  
      if(this.isWhistleblowerAdmin) {
        this.tabs.push({ key: 'settings', label: 'Settings', });
  
        if(!this.isWhistleblowerComplianceOfficer) {
          this.tabs.push({ key: 'categories', label: 'Categories' });
        }
      }

      if (this.tabsDefaultOption) {
        this.selectedTab = _.cloneDeep(this.tabsDefaultOption)
      }

    } catch(err) {
      this.$router.push('/');
    }
  }
}
